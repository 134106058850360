<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <b-row>
            <b-col><h6 class="mb-0">Chi tiết yêu cầu khóa tài khoản</h6></b-col>
            <b-col>
              <div class="text-right" v-if="item.status === 'PENDING'">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="showModal('REJECTED')"
                  >
                    Từ chối
                  </button>
                  <button
                    type="button"
                    class="btn btn-success"
                    @click="showModal('APPROVED')"
                  >
                    Duyệt
                  </button>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>

        <b-row>
          <b-col class="col-md-6">
            <div class="text-center pt-3 pb-3 bg-success">
              <strong>Thông tin chi tiết</strong>
            </div>
            <table class="table">
              <tbody>
                <tr>
                  <td>Họ tên</td>
                  <td>{{ item.name }}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{{ item.email }}</td>
                </tr>
                <tr>
                  <td>SĐT</td>
                  <td>{{ item.phone }}</td>
                </tr>
                <tr>
                  <td>Số CCCD</td>
                  <td>{{ item.id_card_no }}</td>
                </tr>
                <tr>
                  <td>Số dư ví (VNĐ)</td>
                  <td>{{ numberFormat(parseInt(item.balance)) }}</td>
                </tr>
                <tr>
                  <td>Ngày yêu cầu</td>
                  <td>{{ item.created_at }}</td>
                </tr>
                <tr>
                  <td>Trạng thái</td>
                  <td>
                    <span
                      :class="`rounded p-1 bg-${
                        convertStatusLabel(item.status).class
                      }`"
                      >{{ convertStatusLabel(item.status).label }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>Người duyệt</td>
                  <td>{{ item.approved_by }}</td>
                </tr>
                <tr>
                  <td>Người xử lý</td>
                  <td>{{ item.approved_at }}</td>
                </tr>
              </tbody>
            </table>

            <table
              class="table table-striped table-hover"
              v-if="item.get_transactions"
            >
              <thead>
                <tr>
                  <td>Ngày giao dịch</td>
                  <td>Loại giao dịch</td>
                  <td>Giá trị giao dịch</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="transaction in item.get_transactions"
                  :key="transaction"
                >
                  <td>{{ transaction.transaction_date }}</td>
                  <td>{{ transaction.action }}</td>
                  <td>{{ numberFormat(parseInt(transaction.amount)) }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col class="col-md-6">
            <div class="card_front mb-3">
              <img
                :src="item.card_front"
                class="img-fluid"
                alt=""
                v-if="item.card_front"
              />
            </div>

            <div class="card_back mt-3">
              <img
                :src="item.card_back"
                class="img-fluid"
                alt=""
                v-if="item.card_back"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <table class="table table-striped table-hover" v-if="item.get_logs">
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Ngày yêu cầu</th>
                  <th>Ngày xử lý</th>
                  <th>Trạng thái</th>
                  <th>Người duyệt</th>
                  <th>Lý do</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(log, index) in item.get_logs" :key="log">
                  <td>{{ ++index }}</td>
                  <td>{{ log.created_at }}</td>
                  <td>{{ log.updated_at }}</td>
                  <td>
                    <span
                      :class="`rounded p-1 bg-${
                        convertStatusLabel(log.status).class
                      }`"
                      >{{ convertStatusLabel(log.status).label }}</span
                    >
                  </td>
                  <td>{{ log.approved_by }}</td>
                  <td>{{ log.reason }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>

    <b-modal id="my-modal" hide-footer ref="myModal">
      <b-row>
        <b-col>
          <b-form-group label="Lý do phê duyệt (*)">
            <b-form-textarea
              class="form-control"
              v-model="approveData.reason"
              rows="5"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-center">
            <button class="btn btn-danger mr-3" @click="cancelAction()">
              Hủy
            </button>
            <button class="btn btn-primary" @click="approveAction()">
              Duyệt
            </button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  components: {},
  data() {
    return {
      id: this.$route.query.id,
      item: {},
      approveData: {
        reason: null,
        id: this.$route.query.id,
      },
      action: "APPROVED",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Yêu cầu khóa tài khoản", route: "/#/tools/user/request-lock" },
    ]);
  },
  methods: {
    convert(params) {
      for (let key in params) {
        if (params[key] == "" || params[key] == null) {
          delete params[key];
        }
      }
      return params;
    },
    async getDetail() {
      this.item = null;
      this.$bus.$emit("show-loading", true);
      CmsRepository.detailRequestLockAccount(this.id)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.item = response.data.data.data;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    showModal(action = "APPROVED") {
      this.action = action;
      this.$bvModal.show("my-modal");
    },
    approveAction() {
      if (this.action == null || this.action === "") {
        this.notifyAlert("warn", "Loại hành động không được để trống");
        return false;
      }

      if (this.approveData.reason == null || this.approveData.reason === "") {
        this.notifyAlert("warn", "Lý do không được để trống");
        return false;
      }

      let params = this.approveData;
      params = this.convert(params);

      if (this.action === "APPROVED") {
        return this.approve(params);
      }

      return this.reject(params);
    },
    cancelAction() {
      this.action = null;
      this.approveData.reason = null;
      this.$bvModal.hide("my-modal");
    },
    approve(params) {
      this.$bus.$emit("show-loading", true);
      CmsRepository.approveRequestLockAccount(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            this.$bus.$emit("show-loading", false);
            return;
          }

          window.location.reload();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    reject(params) {
      this.$bus.$emit("show-loading", true);
      CmsRepository.rejectRequestLockAccount(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          window.location.reload();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    convertStatusLabel(status) {
      let arrStatus = {
        PENDING: { class: "warning", label: "Chờ phê duyệt" },
        REJECTED: { class: "danger", label: "Từ chối" },
        APPROVED: { class: "success", label: "Đã duyệt" },
      };

      if (arrStatus[status] === undefined) {
        return { class: "warning", label: "Chờ phê duyệt" };
      }

      return arrStatus[status];
    },
  },
  created() {
    this.getDetail();
  },
  watch: {},
};
</script>
